import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Splash from "../../components/splash"
import LongBlocks from "../../components/longBlocks"

import { MEDIA } from "../../config"

const MediaPage = ({ images }) => (
  <Layout>
    <SEO title="Media" />
    <Splash title="Media" subtitle="">
      <Img style={{ minHeight: "150px" }} fluid={images[0]} />
    </Splash>
    {/* <MiddleBox>
      To learn more about the history and details of select assets that Essex
      Capital has acquired, developed, and redeveloped, please click on the
      individual case study profiles below.
    </MiddleBox> */}
    <LongBlocks blocks={renderBlocks()} />
  </Layout>
)

const renderBlocks = () => {
  return Object.keys(MEDIA).map(k => {
    const media_ = MEDIA[k]
    const title = media_.title
    const location = media_.publication
    const text = media_.text
    const href = media_.href
    const buttonText = "View entire article"
    return {
      title,
      buttonText,
      text,
      href,
      location,
      media: k,
    }
  })
}

export default props => {
  const data = useStaticQuery(graphql`
    query {
      splashImage: file(relativePath: { eq: "banners/media.png" }) {
        childImageSharp {
          fluid(maxWidth: 1180) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const splashImage = data.splashImage.childImageSharp.fluid

  const images = [splashImage, ,]
  return <MediaPage {...props} images={images} />
}
